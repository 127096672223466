import * as React from 'react';
import { graphql, type PageProps } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useEffect, useState } from 'react';
import SpinnerDots from '../../components/spinner-dots/spinner-dots';
import { getRedirectLink } from '../../services/campaign.service';
import { SessionStorageKeys } from '../../utils/session-storage-utils';
import { type RedirectOut } from '../../types/redirect-out.model';
import { Language } from '../../types/enums/language.enum';
import * as styles from './wait-screen.module.scss';

function WaitScreenPage({ location }: PageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [canRedirect, setCanRedirect] = useState(false);
  const [targetUrl, setTargetUrl] = useState<string | null>(null);
  const { t, language } = useI18next();

  const params = new URLSearchParams(location.search);
  const logoUrl = params.get('logoUrl');
  const shopName = params.get('shopName');
  const association = params.get('association');

  const redirectToPage = () => {
    if (window && targetUrl) {
      window.location.replace(targetUrl);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setTimeout(() => { setCanRedirect(true); }, 1000);
    }, 2000);

    const redirectData = JSON.parse(sessionStorage.getItem(SessionStorageKeys.WaitScreenRedirectData)!) as RedirectOut | null;
    if (redirectData != null) {
      const redirectOut = JSON.parse(sessionStorage.getItem(SessionStorageKeys.WaitScreenRedirectData)!);
      getRedirectLink(redirectOut, Language[language.toUpperCase() as keyof typeof Language])
        .then((link) => {
          sessionStorage.removeItem(SessionStorageKeys.WaitScreenRedirectData);
          setTargetUrl(link);
        });
    }
  }, []);

  useEffect(() => {
    if (canRedirect) {
      redirectToPage();
    }
  }, [canRedirect, targetUrl]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img alt="logo" className={styles.logo} src={logoUrl!} />
        <div className={styles.title}>
          {t('WaitScreen_Line1', { shop: shopName, association })}
        </div>

        <SpinnerDots showBackdrop={false} isLoading={isLoading} />
        {!isLoading
          ? (
            <div>
              <div className={styles.box}>
                <span>{t('WaitScreen_Ok')}</span>
                <span className={styles.checkmark}>&#10004;</span>
              </div>
            </div>
          ) : null}
      </div>
    </div>
  );
}

export const query = graphql`
    query WaitScreenPageQuery($language: String!) {
        locales: allLocale(filter: {ns: {in: ["WaitScreen"]}, language: {eq: $language}}) {
          edges {
            node {
              ns
              data
              language
            }
          }
        }
    }
`;

export default WaitScreenPage;
